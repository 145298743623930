import React, { useEffect, useContext } from "react";
import Spinner from "../../layout/Spinner";
import ScreenContext from "../../../context/screen/ScreenContext";
import "./FlexDisplay.css";

const FlexDisplay = () => {
  const flexVideo =
    "https://player.vimeo.com/progressive_redirect/playback/1010004557/rendition/1080p/file.mp4?loc=external&signature=e068c12117ff0af996e0f576c1a8b454ef46309ad911b7c3b8a6eac68771db19";
  const screenContext = useContext(ScreenContext);

  const { loading, screen, sendScreenStatus } = screenContext;

  useEffect(() => {
    // set a 60 second timer
    const interval = setInterval(() => {
      // send screen status update & check if workout should update
      if (screen) sendScreenStatus(screen.id);
    }, 60 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  });
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="FlexDisplay">
          <video
            src={flexVideo}
            autoPlay={true}
            muted={true}
            loop={true}
            className="FlexVideo"
            id="videoElement"
          ></video>
        </div>
      )}
    </>
  );
};

export default FlexDisplay;
